<template>
  <a-modal
    :visible="visible"
    title="創建表單"
    :maskClosable="false"
    :style="{ top: '40px' }"
  >
    <!-- Form -->
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    > 
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="表單名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入表單名稱' }]"
          >
            <a-input
              v-model:value="formState.name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="表單簡介"
            name="description"
          >
            <a-input
              v-model:value="formState.description"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="填表身份"
          >
            <a-select
              v-model:value="formState.bind_roles"
              :options="roleOptions"
              :disabled="submitting"
              mode="multiple"
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="備註 (自行紀錄，不會呈現給會員看到)"
            name="note"
          >
            <a-textarea
              v-model:value="formState.note"
              :auto-size="{ minRows: 3 }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- Form -->
    
    <a-typography-text
      type="secondary"
      :style="{ display: 'block', textAlign: 'right' }"
    >表單內容可以在創建表單後進行設定</a-typography-text>

    <template #footer>
      <a-button key="cancel" :disabled="loading" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">確認創建表單</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import api from '@/apis'
import RoleUtil from '@/utils/RoleUtil'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    /* Loading */
    const loading = ref(false);

    /* Api */
    

    /* Form State */
    const initialState = {
      name: '',
      description: '',
      bind_roles: [],
      note: ''
    }
    const formState = reactive({ ...initialState });

    function resetForm() {
      Object.assign(formState, initialState);
    }

    /* Form Options */
    // bind role
    const roleOptions = ref([]);
    RoleUtil.list().forEach(item => {
      if (item.id < 4) {
        roleOptions.value.push({
          label: item.roleNameCH,
          value: item.id
        });
      }
    });

    return {
      /* Loading */
      loading,

      /* Form State */
      formState,
      resetForm,

      /* Form Options */
      roleOptions,

      /* Api */
      api
    }
  },
  methods: {
    async handleOk() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }
      
      /* Create course */
      this.loading = true;

      try {
        await this.api.v1.form.create(this.formState);
        message.success('創建表單成功');
      } catch (error) {
        console.log(error);
        message.error('創建表單錯誤');
        
        this.handleCancel();
        this.loading = false; 
        return
      } finally {
        this.resetForm();
        this.$emit('confirm');
        this.loading = false;
      }
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.formState.name) {
        err = '請輸入表單名稱';
      }

      return err
    }
  }
})
</script>