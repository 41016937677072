
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { PlusOutlined, DownOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import api from '@/apis'
import { Form } from '@/types/Form'
import RoleUtil from '@/utils/RoleUtil'
import DateUtil from '@/utils/DateUtil'
import CreateFormModal from './components/CreateFormModal.vue'
import FormPreview from './components/FormPreview.vue'

const columns = [{
  title: '表單編號',
  dataIndex: 'id',
  key: 'id'
}, {
  title: '表單名稱',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '填寫身份',
  dataIndex: 'bind_roles',
  key: 'bind_roles',
}, {
  title: '建立時間',
  dataIndex: 'created_datetime',
  key: 'created_datetime',
}, {
  title: '操作',
  key: 'action'
}]


export default defineComponent({
  components: {
    CreateFormModal,
    FormPreview,
    PlusOutlined,
    DownOutlined
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Data */
    const formsData = ref<Array<Form>>([]);

    /* Status */
    const loading = ref<boolean>(false);

    /* Api */
    const getFormsData = (async () => {
      loading.value = true;
      
      const response = await api.v1.form.list();
      formsData.value = [ ...response.data.result ];

      loading.value = false;
    });

    const deleteOne = (async (id: string) => {
      try {
        await api.v1.form.deleteOne(id);
        message.success('刪除表單成功');
      } catch (error) {
        console.log(error);
        message.error('刪除表單發生錯誤，請稍後再試');
      } finally {
        getFormsData();
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getFormsData();
    });

    return {
      router,

      /* Store */
      authStore,
      appStore,
      
      /* Api */
      getFormsData,
      deleteOne,

      /* Tabs */
      tabValue: ref('all'), // all|upcoming|active

      /* Data */
      columns,
      formsData,

      /* Utils */
      RoleUtil,
      DateUtil
    }
  },
  data() {
    return {
      /* Create Form Modal */
      createFormModal: {
        visible: false
      },

      /* Form Preview */
      formPreview: {
        visible: false,
        data: {} as Form
      }
    }
  },
  methods: {
    /* Router */
    toViewerPage(id: string) {
      this.router.push({
        name: 'AppFormsViewer',
        params: {
          form_id: id
        }
      });
    },
    toEditorPage(id: string) {
      this.router.push({
        name: 'AppFormsEditor',
        query: {
          id: id
        }
      });
    },

    /* Create Form Modal */
    openCreateFormModal() {
      this.createFormModal.visible = true;
    },
    handleCreateFormModalConfirm() {
      this.createFormModal.visible = false;
      this.getFormsData();
    },
    handleCreateFormCancel() {
      this.createFormModal.visible = false;
    },

    /* Form Preview */
    openFormPreview(data: Form) {
      this.formPreview.data = data;
      this.formPreview.visible = true;
    },
    handleFormPreviewClose() {
      this.formPreview.visible = false;
    }
  }
})
