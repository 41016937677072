<template>
  <a-modal
    wrap-class-name="form-preview-modal"
    :visible="visible"
    :footer="false"
    :closable="false"
    @cancel="handleClose"
    :style="{ width: '100vw', maxWidth: '700px', top: '20px' }"
  >
    <div class="letwecare-form">
      <div class="form__header">
        <div class="header-title">{{ data.name }}</div>
      </div>
      <div class="form__body">
        <a-form>
          <div
            class="form-section"
            v-for="(form, formIdx) in data.form_list"
          >
            <div class="section-title">{{ form.section_title }}</div>

            <div
              class="section-fields"
              v-for="(field, fieldIdx) in form.fields"
            >
              <a-row>
                <a-col :span="24">
                  <a-form-item
                    :label="field.name"
                    :required="field.required"
                  >
                    <template v-if="field.type == 'number'">
                      <a-input-number
                        v-model:value="field.value"
                        :disabled="submitting"
                      />
                    </template>

                    <template v-if="field.type == 'text-field'">
                      <a-input
                        v-model:value="field.value"
                        :disabled="submitting"
                      />
                    </template>

                    <template v-if="field.type == 'textarea'">
                      <a-textarea
                        v-model:value="field.value"
                        :auto-size="{ minRows: 4 }"
                        :disabled="submitting"
                      />
                    </template>

                    <template v-if="field.type == 'radio'">
                      <a-radio-group v-model:value="field.value">
                        <a-radio  
                          v-for="opt in field.selection_items"
                          :value="opt.label"
                        >{{ opt.label }}</a-radio>
                      </a-radio-group>
                    </template>

                    <template v-if="field.type == 'checkbox'">
                      <a-checkbox-group v-model:value="field.value">
                        <a-checkbox  
                          v-for="opt in field.selection_items"
                          :value="opt.label"
                        >{{ opt.label }}</a-checkbox>
                      </a-checkbox-group>
                    </template>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['visible', 'data'],
  components: {},
  setup() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
})
</script>